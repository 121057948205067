







import { Component, Vue } from 'vue-property-decorator';
import ClubDocumentList from './components/ClubDocumentList.vue';

@Component({
  components: {
    'club-documents': ClubDocumentList
  }
})
export default class Documents extends Vue {

}
