import { render, staticRenderFns } from "./Documents.vue?vue&type=template&id=f0f1beb6&"
import script from "./Documents.vue?vue&type=script&lang=ts&"
export * from "./Documents.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/working_dir/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f0f1beb6')) {
      api.createRecord('f0f1beb6', component.options)
    } else {
      api.reload('f0f1beb6', component.options)
    }
    module.hot.accept("./Documents.vue?vue&type=template&id=f0f1beb6&", function () {
      api.rerender('f0f1beb6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/documents/pages/Documents.vue"
export default component.exports