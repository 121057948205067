













































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DocumentCategory } from '../../models/document-category.model';
import { ToastHelper } from '../../../../shared/helpers/toast.helper';
import { Action, Getter } from 'vuex-class';
import { identifier, IDocumentApiService } from '../../services/doument.service';
import { inject } from 'inversify-props';
import dayjs from 'dayjs';

@Component
export default class ClubDocumentCategory extends Vue {

    panelOpened = false;

    @Prop()
    category!: DocumentCategory;

    @inject(identifier)
    private documentService!: IDocumentApiService;

    @Getter
    private clubId!: string;

    togglePanel() : void {
        this.panelOpened = !this.panelOpened;
    }

    parseDate(date: Date) : string {
        return dayjs(date).format('DD-MM-YYYY HH:mm:ss')
    }

    getFileIcon(extension: string) : string {

        // In future here can be added other file extensions
        let css = 'extension-icon ';

        switch(extension) {
            case 'doc':
            case 'docx':
                css += 'far fa-file-word';
                break;
            case 'mp4':
                css += 'far fa-file-video';
                break;
            case 'jpg':
            case 'jpeg':
            case 'png':
                css += 'far fa-file-image';
                break;
            case 'txt':
                css += 'far fa-file-alt';
                break;
            case 'pdf':
                css += 'far fa-file-pdf'; 
                break;
            default:
                css += 'far fa-file';
                break;
        }

        return css;
    }


    async download(fileId: string, fileName: string) : Promise<void> {
        try {
            const blob = await this.documentService.downloadFile(this.clubId, fileId);

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            link.download = fileName;
            
            document.body.appendChild(link);
            link.click();
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
        }
    }
}
