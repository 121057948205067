var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-12 category-container" },
    [
      _c("div", { staticClass: "header", on: { click: _vm.togglePanel } }, [
        _c("div", [
          _c("span", [_vm._v(_vm._s(_vm.category.categoryName) + " ")]),
          _c("span", { staticClass: "badge badge-secondary badge-pill ml-2" }, [
            _vm._v(_vm._s(_vm.category.documentFiles.length)),
          ]),
        ]),
        _c("div", [
          _vm.panelOpened
            ? _c("i", { staticClass: "icon fas fa-chevron-up" })
            : _c("i", { staticClass: "icon fas fa-chevron-down" }),
        ]),
      ]),
      _c(
        "b-collapse",
        {
          model: {
            value: _vm.panelOpened,
            callback: function ($$v) {
              _vm.panelOpened = $$v
            },
            expression: "panelOpened",
          },
        },
        [
          _c("div", { staticClass: "body" }, [
            _vm.category.documentFiles.length === 0
              ? _c("div", { staticClass: "no-items" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("documents.noDocumentsCategory")) + " "
                  ),
                ])
              : _c("div", { staticClass: "table-content" }, [
                  _c(
                    "table",
                    { staticClass: "table" },
                    _vm._l(_vm.category.documentFiles, function (document) {
                      return _c("tr", { key: document.id }, [
                        _c("td", { attrs: { width: "5%" } }, [
                          _c("i", {
                            staticClass: "doc-extension",
                            class: _vm.getFileIcon(document.extension),
                          }),
                        ]),
                        _c("td", { attrs: { width: "45%" } }, [
                          _c("span", { staticClass: "document-desc" }, [
                            _vm._v(_vm._s(document.description)),
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "document-name" }, [
                            _vm._v(_vm._s(document.fileName)),
                          ]),
                        ]),
                        _c("td", { attrs: { width: "20%" } }, [
                          _c("span", { staticClass: "author-name" }, [
                            _vm._v(
                              _vm._s(document.createdBy.firstName) +
                                " " +
                                _vm._s(document.createdBy.lastName)
                            ),
                          ]),
                        ]),
                        _c("td", { attrs: { width: "20%" } }, [
                          _c("span", { staticClass: "document-date" }, [
                            _vm._v(_vm._s(_vm.parseDate(document.createdAt))),
                          ]),
                        ]),
                        _c(
                          "td",
                          { attrs: { width: "10%" } },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "success", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.download(
                                      document.id,
                                      document.fileName
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("documents.download")))]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }