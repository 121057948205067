var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoading
    ? _c("div", { staticClass: "col-12 container" }, [
        _c("div", { staticClass: "body" }, [
          _vm.categoriesWithDocuments.length === 0
            ? _c("div", { staticClass: "p-3" }, [
                _c("span", [_vm._v(_vm._s(_vm.$t("documents.noDocuments")))]),
              ])
            : _c(
                "div",
                _vm._l(_vm.categoriesWithDocuments, function (category) {
                  return _c("document-category", {
                    key: category.name,
                    attrs: { category: category },
                  })
                }),
                1
              ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }