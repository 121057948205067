















import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { DocumentCategory } from '../../models/document-category.model';
import { ToastHelper } from '../../../../shared/helpers/toast.helper';
import ClubDocumentCategory from './ClubDocumentCategory.vue';

@Component({
    components: {
        'document-category': ClubDocumentCategory
    }
})
export default class ClubDocumentList extends Vue {
    isLoading = true;

    @Action
    private loadCategoriesWithDocuments!: () => Promise<void>;
    @Action
    private setLoading!: (loadingn: boolean) => Promise<void>

    @Getter
    categoriesWithDocuments!: DocumentCategory[];

    async created() {
        await this.reloadDocuments();
    }

    async reloadDocuments() : Promise<void> {
        try {
            this.isLoading = true;
            await this.loadCategoriesWithDocuments();
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
        }
        finally {
            this.isLoading = false;
            await this.setLoading(false);
        }
    }
}
